<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="queryParams.name"
          placeholder="请输入名称"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="问候语" prop="text">
        <el-input
          v-model="queryParams.text"
          placeholder="请输入问候语"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="模型" prop="model">
        <el-select v-model="queryParams.model" placeholder="请选择模型" clearable>
          <el-option
            v-for="dict in sys_ai_model"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="逻辑删除" prop="isDeleted">
        <el-select v-model="queryParams.isDeleted" placeholder="请选择逻辑删除" clearable>
          <el-option
            v-for="dict in logic_deleted"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
        >删除</el-button>
      </el-col>

    </el-row>

    <el-table v-loading="loading" :data="aiList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="主键" align="center" prop="id" />
      <el-table-column label="名称" align="center" prop="name" />
      <el-table-column label="头像" align="center" prop="avatar" width="100">
        <template slot-scope="scope">
          <el-image :src="scope.row.avatar" :preview-src-list="[scope.row.avatar]" :width="50" :height="50"/>
        </template>
      </el-table-column>
      <el-table-column label="问候语" align="center" prop="text" />
      <el-table-column label="模型" width="180" align="center" prop="model">
        <template slot-scope="scope">
          <el-tag>{{scope.row.model}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="调用地址" align="center" prop="url" />
      <el-table-column label="代理地址" align="center" prop="apiUrl" />
      <el-table-column label="接口token" align="center" prop="token" />
      <el-table-column label="逻辑删除" align="center" prop="isDeleted">
        <template slot-scope="scope">
          <el-tag>
            {{scope.row.isDeleted==0?'正常':'删除'}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
        v-if="aiList.length"
        :small="true"
        :pager-count="pageCount"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryParams.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryParams.pageSize"
        :layout="layout"
        :total="total">
    </el-pagination>

    <!-- 添加或修改AI管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <el-upload
              class="avatar-uploader"
              action="https://47.120.61.197/oss/uploadOssFile"
              :show-file-list="false"
              :headers="{token: token}"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
            <el-image fit="contain" v-if="form.avatar" :src="form.avatar" class="avatar"></el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

        </el-form-item>
        <el-form-item label="问候语" prop="text">
          <el-input v-model="form.text" placeholder="请输入问候语" />
        </el-form-item>
        <el-form-item label="模型" prop="model">
          <el-select v-model="form.model" placeholder="请选择模型">
            <el-option
              v-for="dict in sys_ai_model"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="调用地址" prop="url">
          <el-input v-model="form.url" placeholder="请输入调用地址" />
        </el-form-item>
        <el-form-item label="代理地址" prop="apiUrl">
          <el-input v-model="form.apiUrl" placeholder="请输入代理地址" />
        </el-form-item>
        <el-form-item label="接口token" prop="token">
          <el-input v-model="form.token" placeholder="请输入接口token" />
        </el-form-item>
        <el-form-item label="逻辑删除" prop="isDeleted">
          <el-select v-model="form.isDeleted" placeholder="请选择逻辑删除">
            <el-option
              v-for="dict in logic_deleted"
              :key="dict.value"
              :label="dict.label"
              :value="parseInt(dict.value)"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listAi, getAi, delAi, addAi, updateAi } from "@/api/admin/ai";

export default {
  data() {
    return {
      pageCount:9,
      token: this.$store.state.user.token,
      layout:'total, sizes, prev, pager, next, jumper',
      sys_ai_model:[
        { label: "GPT3.5", value: "gpt-3.5-turbo" },
        { label: "文心一言", value: "wxyy" },
        { label: "DALLE-3", value: "dalle-3" },
        { label: "STABLE-D", value: "stable-diffusion" },
        { label: "GPT4", value: "gpt-4" },
        { label: "CLAUDE3-ALL", value: "claude-3-all" },
        { label: "GPT4-PLUS", value: "gpt-4-plus" },
        { label: "GPT3.5-16k", value: "gpt-3.5-turbo-16k-0613" },
        { label: "BING", value: "bing" },
        { label: "GPT4-ALL", value: "gpt-4-all" },
      ],
      logic_deleted:[
        { label: "正常", value: 0 },
        { label: "删除", value: 1 },
      ],
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // AI管理表格数据
      aiList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        text: null,
        model: null,
        isDeleted: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      }
    };
  },
  created() {
    this.getList();
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.form.avatar = res.data.url;
      console.log(file)
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      const isLt5M = file.size / 1024 / 1024 < 5;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt5M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
      }
      // return isJPG && isLt2M;
      return isLt5M;
    },
    handleResize() {
      if (window.innerWidth < 700) {
        this.pageCount = 5;
        this.layout = 'total,prev, pager, next';
      }else {
        this.pageCount = 9;
        this.layout = 'total, sizes, prev, pager, next, jumper';
      }
    },
    handleCurrentChange(val){
      this.queryParams.pageNum = val;
      this.getList();
    },
    handleSizeChange(val){
      this.queryParams.pageSize = val;
      this.getList();
    },

    /** 查询AI管理列表 */
    getList() {
      this.loading = true;
      listAi(this.queryParams).then(response => {
        this.aiList = response.data.data.list;
        this.total = response.data.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        name: null,
        avatar: null,
        text: null,
        model: null,
        url: null,
        token: null,
        isDeleted: null,
        createTime: null,
        updateTime: null
      };
      // this.resetForm("form");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加AI管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getAi(id).then(response => {
        this.form = response.data.data.ai;
        this.open = true;
        this.title = "修改AI管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateAi(this.form).then(response => {
              this.$message.success("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addAi(this.form).then(response => {
              this.$message.success("添加成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除AI管理编号为"' + ids + '"的数据项？').then(function() {
        return delAi(ids);
      }).then(() => {
        this.getList();
        this.$message.success("删除成功");
      }).catch(() => {});
    },

  }
};
</script>
