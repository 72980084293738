<template>
  <div class="mess-container" :class="{darkMess:theme=='dark'}">
    <div class="mess" v-if="isShowMess||isBigView" :class="{darkTab:theme=='dark'}">
      <!--添加一个搜索框,可以通过会话名称进行过滤-->
      <div style="padding-top: 10px;margin-left: 10px;margin-right: 10px" :class="{darkSearch:theme=='dark'}">
        <el-input placeholder="搜索" clearable v-model="searchText"></el-input>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick" :stretch=true>
        <el-tab-pane label="消息" name="first">
          <!--消息列表,显示头像和名称-->
          <div class="chat-list" style="height: calc(100vh - 120px);scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;">
            <div class="chat_list_item" v-for="item in filterUserSessionList" :key="item.id" @click="getChatObj(item)"
                 @contextmenu.prevent="show_rightMenu_mess_content2($event, item)"
                 :class="{ selected: selectedItem === item,isTopListItem: item.top>0}" :theme="theme">
              <div style="width: 100%;display: flex;
  align-items: center;">
                <el-badge :value="item.unreadCount" :max="99" class="item" v-if="item.unreadCount>0">
                  <img :src="item.sessionAvatar" shape="square" alt=""
                       style="margin-left: 10px;width:40px;height:40px;vertical-align: middle;border-radius:10px"/>
                </el-badge>
                <img v-else :src="item.sessionAvatar" shape="square" alt=""
                     style="margin-left: 10px;width:40px;height:40px;vertical-align: middle;border-radius:10px"/>

                <div style="margin-left: 20px;">
                  <p style="font-size: 12px;margin-top: 0px;margin-bottom: 0px;" :class="{darkName:theme=='dark'}">
                    {{ item.sessionName }}
                    <span style="color: mediumspringgreen">({{item.onlineStatus==0?'离线':'在线'}})</span>
                  </p>
                  <p style="font-size: 10px;margin: 4px 0px 0px;color:#a19f9f;" class="text-overflow">
                    {{ item.lastMess }}
                  </p>
                </div>
                <div style="position: sticky;left: 200px">
                  <p style="font-size: 10px;margin-top: 0px;margin-bottom: 0px;color:#a19f9f;">{{ item.lastTime }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="AI" name="second">
          <!--ai列表,显示头像和名称-->
          <div class="ai-list" style="height: calc(100vh - 120px);scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;">
            <div class="ai_list_item" v-for="item in filterAiList" :key="item.id" @click="getAi(item)"
                 :class="{ selected: selectedItem === item }">
              <div style="width: 100%;">
                <img :src="item.avatar" shape="square" alt=""
                     style="margin-left: 10px;width:40px;height:40px;vertical-align: middle;border-radius:10px"/>
                <span style="margin-left: 10px;font-size: 14px" :class="{darkName:theme=='dark'}">{{ item.name }}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div v-if="selectedItem&&isBigView&&activeName=='second'" class="page">
      <!--对话框头部-->
      <div class="dlog_header">
        <el-image
            shape="square"
            style="width: 35px; height: 35px;margin: 0;border-radius:5px;margin-left: 20px;margin-right: 10px;"
            :src="selectedAI.avatar"
        >
        </el-image>

        <span :class="{darkName:theme=='dark'}">{{ selectedAI.name }}</span>
      </div>
      <!--对话框内容-->
      <div id="content_overflow" class="dlog_content">
        <div
            v-show="list.length"
            :key="index"
            class="dlog_content_item"
            :class="item.type === 0 ? 'problemList' : 'answerList'"
            v-for="(item,index) in list"
        >
          <div style="display: flex; align-items: center; text-align: center;" v-if="item.role=='tip'&&item.content">
            <div style="content: ''; flex: 1; border-bottom: 1px dashed #8c8b8b; margin-right: .5em;"></div>
            <span style="padding: 0 10px;">{{ item.content }}</span>
            <div style="content: ''; flex: 1; border-bottom: 1px dashed #8c8b8b; margin-left: .5em;">
            </div>
          </div>
          <!--其他用户的消息展示[单聊]-->
          <div class="content_other" v-if="item.role=='assistant'&&item.content">
            <div>
              <el-image
                  :src="item.avatar"
                  style="width: 35px; height: 35px;border-radius:5px;margin-top: 5px;"></el-image>
            </div>
            <div class="mess_other">
              <div>
                <span style="font-size: 8px;" :class="{darkName:theme=='dark'}">{{
                    formatDate(item.sendTime)
                  }}  {{ item.nickname }}</span>
              </div>
              <!--              <div v-if="item.contentType === 'image'" class="img_left">-->
              <!--                <el-image :src="item.content" :preview-src-list="imgNowList" class="images"></el-image>-->
              <!--              </div>-->
              <div class="content_other_bgd" @contextmenu.prevent="show_rightMenu_mess_content3($event, item)"
              >
                <!--                <span class="mess_content_msg" v-if="item.sendId==1" style="font-weight: 500" v-html="item.content"></span>-->
                <!--                <span class="mess_content_msg" v-else v-html="item.content"></span>-->
                <Viewer
                    class="viewer"
                    :tabindex="2"
                    :sanitize="23"
                    :value="item.content"
                ></Viewer>
              </div>
            </div>
          </div>
          <!--本用户的消息展示-->
          <div class="content_me" v-if="item.role=='user'">
            <div class="mess_me">
              <div>
                <span style="font-size: 8px;" :class="{darkName:theme=='dark'}">{{
                    formatDate(item.sendTime)
                  }}  {{ item.nickname }}</span>
              </div>
              <!--              <div v-if="item.contentType === 'image'" class="img">-->
              <!--                <el-image :src="item.content" :preview-src-list="imgNowList" class="images"></el-image>-->
              <!--              </div>-->
              <div class="content_me_bgd" @contextmenu.prevent="show_rightMenu_mess_content3($event, item)"
              >
                <Viewer
                    class="viewer"
                    :tabindex="2"
                    :sanitize="23"
                    :value="item.content"
                ></Viewer>
              </div>
            </div>
            <div>
              <el-image
                  :src="item.avatar"
                  style="width: 35px; height: 35px;border-radius:5px;margin-top: 5px;"></el-image>
            </div>
          </div>
        </div>

        <div v-show="loading" class="content_other">
          <div>
            <el-image
                :src="selectedAI.avatar"
                style="width: 35px; height: 35px;border-radius:5px;margin-top: 5px;"></el-image>
          </div>
          <div class="img_left">
            <el-image :src="require('@/assets/img/loading.gif')"></el-image>
          </div>
        </div>
      </div>
      <!--对话框底部-->
      <div class="dlog_footer">
        <div class="footer_content">
          <!--聊天功能按钮-->
          <div class="footer_content_menu">
            <!--表情包发送功能-->
            <el-dropdown placement="top-start">
              <div class="footer_content_menu_face">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-happy-"></use>
                </svg>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="face_list">
                  <div v-for="(item, index) in emojiList" v-bind:key="item.id" class="face-item"
                       @click="emojiDown(index)">
                    {{ item }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>

            <div class="footer_content_menu_img">
              <el-tooltip class="item" effect="dark" content="清空上下文" placement="top">
                <label for="fileInput" @click="clean()">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-qingchu1"></use>
                  </svg>
                </label>
              </el-tooltip>
            </div>
            <div class="footer_content_menu_img">
              <el-tooltip class="item" effect="dark" content="删除聊天记录" placement="top">
                <label for="fileInput" @click="deleteAIRecord()">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-qingkong"></use>
                  </svg>
                </label>
              </el-tooltip>
            </div>
          </div>
          <div class="inputDeep" :class="{darkInput:theme=='dark'}">
            <el-input style="border: 0px" type="textarea" :rows="2" v-model="question"
                      placeholder="按Ctrl + Enter 换行"
                      @keydown.enter.native="keyDown"></el-input>
            <button @click="send"
                    style="float:right;margin-right:20px;border: none; background-color: transparent; color: rgb(155,158,164);cursor: pointer;font-size: 14px">
              发送
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="selectedItem&&isBigView&&activeName=='first'" class="page">
      <!--对话框头部-->
      <div class="dlog_header">
        <el-image
            @click="$router.push(`/user/${selectedItem.acceptId}/info`)"
            shape="square"
            style="width: 35px;cursor: pointer; height: 35px;margin: 0;border-radius:5px;margin-left: 20px;margin-right: 10px;"
            :src="selectedItem.sessionAvatar"
        >
        </el-image>

        <span :class="{darkName:theme=='dark'}" style="cursor: pointer"
              @click="$router.push(`/user/${selectedItem.acceptId}/info`)">{{ selectedItem.sessionName }}</span>
        <span style="color: mediumspringgreen">({{selectedItem.onlineStatus==0?'离线':'在线'}})</span>
      </div>
      <!--对话框内容-->
      <div id="content_overflow2" class="dlog_content">
        <div
            v-show="userMessageRecordList.length"
            :key="index"
            class="dlog_content_item"
            :class="item.type === 0 ? 'problemList' : 'answerList'"
            v-for="(item,index) in userMessageRecordList"
        >
          <!--其他用户的消息展示[单聊]-->
          <div class="content_other" v-if="item.sendId!=userInfo.id">
            <div>
              <el-image
                  @click="$router.push(`/user/${item.sendId}/info`)"
                  :src="item.sendAvatar"
                  style="width: 35px; height: 35px;cursor: pointer;border-radius:5px;margin-top: 5px;"></el-image>
            </div>
            <div class="mess_other">
              <div>
                <span style="font-size: 8px;cursor: pointer" :class="{darkName:theme=='dark'}"
                      @click="$router.push(`/user/${item.sendId}/info`)">{{
                    formatDate(item.sendTime)
                  }}  {{ item.sendNickname }}</span>
              </div>
              <div v-if="item.contentType === 'image'" class="img_left">
                <el-image :src="item.content" :preview-src-list="imgNowList" class="images"></el-image>
              </div>
              <div v-else class="content_other_bgd" @contextmenu.prevent="show_rightMenu_mess_content($event, item)"
              >
                <Viewer
                    class="viewer"
                    :tabindex="2"
                    :sanitize="23"
                    :value="item.content"
                ></Viewer>
              </div>
            </div>
          </div>
          <!--本用户的消息展示-->
          <div class="content_me" v-if="item.sendId==userInfo.id">
            <div class="mess_me">
              <div>
                <span style="font-size: 8px;cursor: pointer" @click="$router.push(`/user/${item.sendId}/info`)"
                      :class="{darkName:theme=='dark'}">{{ formatDate(item.sendTime) }}  {{ item.sendNickname }}</span>
              </div>
              <div v-if="item.contentType === 'image'" class="img">
                <el-image :src="item.content" :preview-src-list="imgNowList" class="images"></el-image>
              </div>
              <div v-else class="content_me_bgd" @contextmenu.prevent="show_rightMenu_mess_content($event, item)"
              >
                <Viewer
                    class="viewer"
                    :tabindex="2"
                    :sanitize="23"
                    :value="item.content"
                ></Viewer>
              </div>
            </div>
            <div>
              <el-image
                  @click="$router.push(`/user/${item.sendId}/info`)"
                  :src="item.sendAvatar"
                  style="width: 35px; height: 35px;border-radius:5px;margin-top: 5px;cursor: pointer"></el-image>
            </div>
          </div>
        </div>
      </div>
      <!--对话框底部-->
      <div class="dlog_footer">
        <div class="footer_content">
          <!--聊天功能按钮-->
          <div class="footer_content_menu">
            <!--表情包发送功能-->
            <el-dropdown placement="top-start">
              <div class="footer_content_menu_face">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-happy-"></use>
                </svg>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="face_list">
                  <div v-for="(item, index) in emojiList" v-bind:key="item.id" class="face-item"
                       @click="userEmojiDown(index)">
                    {{ item }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="footer_content_menu_img">
              <label for="fileInput">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-tupian"></use>
                </svg>
              </label>
              <input v-show="false" type="file" accept="image/png, image/jpeg" ref="chartImageBig" id="fileInput"
                     @change="showUpload">
            </div>
            <div class="footer_content_menu_img">
              <el-tooltip class="item" effect="dark" content="删除聊天记录" placement="top">
                <label @click="deleteMessageRecord()">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-qingkong"></use>
                  </svg>
                </label>
              </el-tooltip>
            </div>
          </div>
          <div class="inputDeep" :class="{darkInput:theme=='dark'}">
            <el-input style="border: 0px" type="textarea" :rows="2" v-model="mess"
                      placeholder="按Ctrl + Enter 换行"
                      maxlength="500"
                      @keydown.enter.native="userkeyDown"></el-input>
            <button @click="sendWsMessage()"
                    style="float:right;margin-right:20px;border: none; background-color: transparent; color: rgb(155,158,164);cursor: pointer;font-size: 14px">
              发送
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="blank-page" :class="{darkBlankPage:theme=='dark'}">
      <span>你还未选中或发起聊天，快去跟好友聊一聊吧</span>
    </div>

    <div v-if="selectedItem&&!isShowMess&&activeName=='second'" class="small-view-page">
      <!--添加一个返回的按钮在左上角-->
      <div style="position: sticky;top: 0px;left: 0px;width: 100%;height: 50px;">
        <el-button type="text" icon="el-icon-arrow-left" @click="isShowMess=true">返回</el-button>
      </div>
      <!--对话框头部-->
      <div class="dlog_header">
        <el-image
            shape="square"
            style="width: 35px; height: 35px;margin: 0;border-radius:5px;margin-left: 20px;margin-right: 10px;"
            :src="selectedAI.avatar"
        >
        </el-image>

        <span :class="{darkName:theme=='dark'}">{{ selectedAI.name }}</span>
      </div>
      <!--对话框内容-->
      <div id="content_overflow3" class="dlog_content">
        <div
            v-show="list.length"
            :key="index"
            class="dlog_content_item"
            :class="item.type === 0 ? 'problemList' : 'answerList'"
            v-for="(item,index) in list"
        >
          <div style="display: flex; align-items: center; text-align: center;" v-if="item.role=='tip'&&item.content">
            <div style="content: ''; flex: 1; border-bottom: 1px dashed #8c8b8b; margin-right: .5em;"></div>
            <span style="padding: 0 10px;">{{ item.content }}</span>
            <div style="content: ''; flex: 1; border-bottom: 1px dashed #8c8b8b; margin-left: .5em;">
            </div>
          </div>
          <!--其他用户的消息展示[单聊]-->
          <div class="content_other" v-if="item.role=='assistant'&&item.content">
            <div>
              <el-image
                  :src="item.avatar"
                  style="width: 35px; height: 35px;border-radius:5px;margin-top: 5px;"></el-image>
            </div>
            <div class="mess_other">
              <div>
                <span style="font-size: 8px;" :class="{darkName:theme=='dark'}">{{
                    formatDate(item.sendTime)
                  }}  {{ item.nickname }}</span>
              </div>
              <!--              <div v-if="item.contentType === 'image'" class="img_left">-->
              <!--                <el-image :src="item.content" :preview-src-list="imgNowList" class="images"></el-image>-->
              <!--              </div>-->
              <div class="content_other_bgd" style="font-size: 14px"
                   @contextmenu.prevent="show_rightMenu_mess_content3($event, item)"
              >
                <Viewer
                    class="viewer"
                    :tabindex="2"
                    :sanitize="23"
                    :value="item.content"
                ></Viewer>
              </div>
            </div>
          </div>
          <!--本用户的消息展示-->
          <div class="content_me" v-if="item.role=='user'">
            <div class="mess_me">
              <div>
                <span style="font-size: 8px;" :class="{darkName:theme=='dark'}">{{
                    formatDate(item.sendTime)
                  }}  {{ item.nickname }}</span>
              </div>
              <!--              <div v-if="item.contentType === 'image'" class="img">-->
              <!--                <el-image :src="item.content" :preview-src-list="imgNowList" class="images"></el-image>-->
              <!--              </div>-->
              <div class="content_me_bgd" style="font-size: 14px"
                   @contextmenu.prevent="show_rightMenu_mess_content3($event, item)"
              >
                <Viewer
                    class="viewer"
                    :tabindex="2"
                    :sanitize="23"
                    :value="item.content"
                ></Viewer>
              </div>
            </div>
            <div>
              <el-image
                  :src="item.avatar"
                  style="width: 35px; height: 35px;border-radius:5px;margin-top: 5px;"></el-image>
            </div>
          </div>
        </div>

        <div v-show="loading" class="content_other">
          <div>
            <el-image
                :src="selectedAI.avatar"
                style="width: 35px; height: 35px;border-radius:5px;margin-top: 5px;"></el-image>
          </div>
          <div class="img_left">
            <el-image :src="require('@/assets/img/loading.gif')" class="images"></el-image>
          </div>
        </div>
      </div>
      <!--对话框底部-->
      <div class="dlog_footer">
        <div class="footer_content">
          <!--聊天功能按钮-->
          <div class="footer_content_menu">
            <!--表情包发送功能-->
            <el-dropdown placement="top-start">
              <div class="footer_content_menu_face">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-happy-"></use>
                </svg>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="face_list">
                  <div v-for="(item, index) in emojiList" v-bind:key="item.id" class="face-item"
                       @click="emojiDown(index)">
                    {{ item }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="footer_content_menu_img">
              <el-tooltip class="item" effect="dark" content="清空上下文" placement="top">
                <label @click="clean()">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-qingchu1"></use>
                  </svg>
                </label>
              </el-tooltip>
            </div>
            <div class="footer_content_menu_img">
              <el-tooltip class="item" effect="dark" content="删除聊天记录" placement="top">
                <label @click="deleteAIRecord()">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-qingkong"></use>
                  </svg>
                </label>
              </el-tooltip>
            </div>
          </div>
          <div class="inputDeep" :class="{darkInput:theme=='dark'}">
            <el-input style="border: 0px" type="textarea" :rows="2" v-model="question"
                      placeholder="按Ctrl + Enter 换行"
                      @keydown.enter.native="keyDown"></el-input>
            <button @click="send"
                    style="float:right;margin-right:20px;border: none; background-color: transparent; color: rgb(155,158,164);cursor: pointer;font-size: 14px">
              发送
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedItem&&!isShowMess&&activeName=='first'" class="small-view-page">
      <!--添加一个返回的按钮在左上角-->
      <div style="position: sticky;top: 0px;left: 0px;width: 100%;height: 50px;">
        <el-button type="text" icon="el-icon-arrow-left" @click="isShowMess=true">返回</el-button>
      </div>
      <!--对话框头部-->
      <div class="dlog_header">
        <el-image
            @click="$router.push(`/user/${selectedItem.acceptId}/info`)"
            shape="square"
            style="width: 35px; height: 35px;cursor:pointer;margin: 0;border-radius:5px;margin-left: 20px;margin-right: 10px;"
            :src="selectedItem.sessionAvatar"
        >
        </el-image>

        <span :class="{darkName:theme=='dark'}">{{ selectedItem.sessionName }}</span>
        <span style="color: mediumspringgreen">({{selectedItem.onlineStatus==0?'离线':'在线'}})</span>
      </div>
      <!--对话框内容-->
      <div id="content_overflow4" class="dlog_content">
        <div
            v-show="userMessageRecordList.length"
            :key="index"
            class="dlog_content_item"
            :class="item.type === 0 ? 'problemList' : 'answerList'"
            v-for="(item,index) in userMessageRecordList"
        >
          <!--其他用户的消息展示[单聊]-->
          <div class="content_other" v-if="item.sendId!=userInfo.id">
            <div>
              <el-image
                  @click="$router.push(`/user/${item.sendId}/info`)"
                  :src="item.sendAvatar"
                  style="width: 35px;cursor: pointer; height: 35px;border-radius:5px;margin-top: 5px;"></el-image>
            </div>
            <div class="mess_other">
              <div>
                <span style="font-size: 8px;cursor: pointer" :class="{darkName:theme=='dark'}"
                      @click="$router.push(`/user/${item.sendId}/info`)">{{
                    formatDate(item.sendTime)
                  }}  {{ item.sendNickname }}</span>
              </div>
              <div v-if="item.contentType === 'image'" class="img_left">
                <el-image :src="item.content" :preview-src-list="imgNowList" class="images"></el-image>
              </div>
              <div v-else class="content_other_bgd" @contextmenu.prevent="show_rightMenu_mess_content($event, item)"
              >
                <Viewer
                    class="viewer"
                    :tabindex="2"
                    :sanitize="23"
                    :value="item.content"
                ></Viewer>
              </div>
            </div>
          </div>
          <!--本用户的消息展示-->
          <div class="content_me" v-if="item.sendId==userInfo.id">
            <div class="mess_me">
              <div>
                <span style="font-size: 8px;cursor: pointer" :class="{darkName:theme=='dark'}"
                      @click="$router.push(`/user/${item.sendId}/info`)">{{
                    formatDate(item.sendTime)
                  }}  {{ item.sendNickname }}</span>
              </div>
              <div v-if="item.contentType === 'image'" class="img">
                <el-image :src="item.content" :preview-src-list="imgNowList" class="images"></el-image>
              </div>
              <div v-else class="content_me_bgd" @contextmenu.prevent="show_rightMenu_mess_content($event, item)"
              >
                <Viewer
                    class="viewer"
                    :tabindex="2"
                    :sanitize="23"
                    :value="item.content"
                ></Viewer>
              </div>
            </div>
            <div>
              <el-image
                  @click="$router.push(`/user/${item.sendId}/info`)"
                  :src="item.sendAvatar"
                  style="width: 35px;cursor: pointer; height: 35px;border-radius:5px;margin-top: 5px;"></el-image>
            </div>
          </div>
        </div>
      </div>
      <!--对话框底部-->
      <div class="dlog_footer">
        <div class="footer_content">
          <!--聊天功能按钮-->
          <div class="footer_content_menu">
            <!--表情包发送功能-->
            <el-dropdown placement="top-start">
              <div class="footer_content_menu_face">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-happy-"></use>
                </svg>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="face_list">
                  <div v-for="(item, index) in emojiList" v-bind:key="item.id" class="face-item"
                       @click="userEmojiDown(index)">
                    {{ item }}
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="footer_content_menu_img">
              <label for="fileInput">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-tupian"></use>
                </svg>
              </label>
              <input v-show="false" type="file" ref="chartImageSmall" id="fileInput"
                     @change="showUploadSmall">
            </div>
            <div class="footer_content_menu_img">
              <el-tooltip class="item" effect="dark" content="删除聊天记录" placement="top">
                <label @click="deleteMessageRecord()">
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-qingkong"></use>
                  </svg>
                </label>
              </el-tooltip>
            </div>
          </div>
          <div class="inputDeep" :class="{darkInput:theme=='dark'}">
            <el-input style="border: 0px" type="textarea" :rows="2" v-model="mess"
                      placeholder="按Ctrl + Enter 换行"
                      maxlength="500"
                      @keydown.enter.native="userkeyDown"></el-input>
            <button @click="sendWsMessage()"
                    style="float:right;margin-right:20px;border: none; background-color: transparent; color: rgb(155,158,164);cursor: pointer;font-size: 14px">
              发送
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--发送图片dialog-->
    <el-dialog :visible.sync="isShowDialog"
               class="dialogUpload"
               @closed="diaClose">
      <el-image :src="imageUrl" class="images"></el-image>
      <div class="dialog_btn">
        <el-button @click="diaClose">取消</el-button>
        <!--        <el-button type="primary" :loading="sendImgLoad">发送</el-button>-->
        <el-button type="primary" @click="WssendImg" :loading="sendImgLoad">发送</el-button>
      </div>
    </el-dialog>

    <!--消息右键菜单-聊天内容复制-->
    <div class="rightMenu_mess_content" v-if="isShow_rightMenu_mess_content"
         :style="{'left': rightMenu_mess_content_left + 'px', 'top': rightMenu_mess_content_top + 'px'}">
      <el-card class="rightMenu_mess_content_card">
        <div class="rightMenu_mess_content_card_item">
          <el-button @click="onCopy">复制</el-button>
        </div>
        <!--        <div class="rightMenu_mess_content_card_item">-->
        <!--          <el-button @click="deleteOneMessageRecord()">删除</el-button>-->
        <!--        </div>-->
      </el-card>
    </div>

    <!--ai右键菜单-聊天内容复制-->
    <div class="rightMenu_mess_content" v-if="isShow_rightMenu_mess_content3"
         :style="{'left': rightMenu_mess_content_left3 + 'px', 'top': rightMenu_mess_content_top3 + 'px'}">
      <el-card class="rightMenu_mess_content_card">
        <div class="rightMenu_mess_content_card_item">
          <el-button @click="onCopy2">复制</el-button>
        </div>
        <div class="rightMenu_mess_content_card_item" v-if="selectedAI.name!='文心一言'">
          <el-button @click="deleteAiOneMessageRecord()">删除</el-button>
        </div>
        <div class="rightMenu_mess_content_card_item">
          <el-button @click="reGenerate()">重新生成</el-button>
        </div>
      </el-card>
    </div>


    <!--右键菜单-置顶，隐藏，删除-->
    <div class="rightMenu_mess_content" v-if="isShow_rightMenu_mess_content2"
         :style="{'left': rightMenu_mess_content_left2 + 'px', 'top': rightMenu_mess_content_top2 + 'px'}">
      <el-card class="rightMenu_mess_content_card">
        <div class="rightMenu_mess_content_card_item" v-if="rightMenu_mess_content_item2.top<=0">
          <el-button @click="topSession">置顶</el-button>
        </div>
        <div class="rightMenu_mess_content_card_item" v-if="rightMenu_mess_content_item2.top>0">
          <el-button @click="untopSession">取消置顶</el-button>
        </div>
        <div class="rightMenu_mess_content_card_item">
          <el-button @click="hideSession()">不显示聊天</el-button>
        </div>
        <div class="rightMenu_mess_content_card_item">
          <el-button @click="deleteSession()">删除聊天</el-button>
        </div>
      </el-card>
    </div>

  </div>
</template>
<script>
import {emojiList} from "@/assets/emoji/emoji.json";
import cookie from 'js-cookie'
import mdKatex from '@traptitech/markdown-it-katex'
import MarkdownIt from 'markdown-it'
import "highlight.js/styles/github-dark.min.css";
import ClipboardJS from 'clipboard';
import hljs from "highlight.js";
import {Viewer} from '@bytemd/vue'
import 'bytemd/dist/index.css'  // 导入编辑器样式
import mediumZoom from '@bytemd/plugin-medium-zoom' //图片放大预览
import 'highlight.js/styles/vs.css'
import 'juejin-markdown-themes/dist/juejin.min.css'  // 其实就是需要这个css文件
import ossApi from "@/api/oss";
import messageApi from "@/api/message";
import sessionApi from "@/api/session";
import {sendMessage, removeInitialListener, addInitialListener, removeMyListener, addListener} from '@/utils/websocket'
import store from "@/store";
import aiApi from "@/api/ai";

const plugins = [
  mediumZoom(),
]
export default {
  components: {
    Viewer,
  },
  data() {
    return {
      rightMenu_mess_content_item: null,
      isShow_rightMenu_mess_content: false,
      rightMenu_mess_content_top: 0,
      rightMenu_mess_content_left: 0,
      rightMenu_mess_content_item2: null,
      isShow_rightMenu_mess_content2: false,
      rightMenu_mess_content_top2: 0,
      rightMenu_mess_content_left2: 0,
      rightMenu_mess_content_item3: null,
      isShow_rightMenu_mess_content3: false,
      rightMenu_mess_content_top3: 0,
      rightMenu_mess_content_left3: 0,
      files: null,//上传的文件
      sendImgLoad: false,// 发送图片按钮加载
      imageUrl: '',//图片预览地址
      mess: '',//消息内容
      emojiList: emojiList.split(','), // 表情包列表
      theme: 'light',
      isShowMess: true,
      isBigView: true,
      imgNowList: [],
      userSessionList: [],
      userMessageRecordList: [],
      selectedItem: null,
      searchText: '',
      selectedAI: {},
      list: [],
      question: '',
      loading: false,
      isMobile: false,
      aiChatMess: [],
      isShowDialog: false, // 发送图片预览dialog
      activeName: 'first',
      aiList: []
    };
  },
  created() {
    this.getAiList();
    //获取会话列表
    this.getUserSessions();
    this.addCloseRightMenu();
  },
  watch: {
    '$store.state.user.theme': {
      handler: function (val) {
        this.theme = val;
      },
      immediate: true
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
    filterUserSessionList() {
      if (this.searchText) {
        return this.userSessionList.filter(item =>
            item.sessionName.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        return this.userSessionList;
      }
    },
    filterAiList() {
      if (this.searchText) {
        return this.aiList.filter(item =>
            item.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      } else {
        return this.aiList;
      }
    },
  },
  mounted() {
    //过几秒删除初始化监听器
    setTimeout(() => {
      removeInitialListener();
    }, 2000);
    addListener(this.handleAcceptMessage)
    window.addEventListener('resize', this.handleResize);
    this.handleResize();//先调用一次，防止刷新页面时，窗口宽度小于650px，但是isBigView为true
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    this.$store.state.user.acceptId = null;
    this.selectedItem = null;
    removeMyListener(this.handleAcceptMessage)
    addInitialListener();
  },
  methods: {
    reGenerate() {
      //判断选中的是ai还是用户
      if (this.rightMenu_mess_content_item3.role === 'user') {
        //删除选中的下一条消息
        //查找选中消息的索引
        let index = this.list.findIndex(item => item === this.rightMenu_mess_content_item3);
        //拿到下一条消息
        let nextMess = this.list[index + 1];
        //删除选中消息
        this.list = this.list.filter(item => item !== this.rightMenu_mess_content_item3);
        //删除下一条消息
        this.list = this.list.filter(item => item !== nextMess);
        //重写组装上下文
        this.aiChatMess = [];
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].role === "user") {
            this.aiChatMess.push({
              "role": "user",
              "content": this.list[i].content
            })
          } else {
            this.aiChatMess.push({
              "role": "assistant",
              "content": this.list[i].content
            })
          }
        }
        //重新保存聊天记录到localstorage
        window.localStorage.setItem(this.selectedAI.name, JSON.stringify(this.list));
        //重写发送消息
        this.question = this.rightMenu_mess_content_item3.content;
        this.send();
      } else {
        //删除选中的上一条消息
        //查找选中消息的索引
        let index = this.list.findIndex(item => item === this.rightMenu_mess_content_item3);
        //拿到上一条消息
        let lastMess = this.list[index - 1];
        //删除选中消息
        this.list = this.list.filter(item => item !== this.rightMenu_mess_content_item3);
        //删除上一条消息
        this.list = this.list.filter(item => item !== lastMess);
        //重写组装上下文
        this.aiChatMess = [];
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].role === "user") {
            this.aiChatMess.push({
              "role": "user",
              "content": this.list[i].content
            })
          } else {
            this.aiChatMess.push({
              "role": "assistant",
              "content": this.list[i].content
            })
          }
        }
        //重新保存聊天记录到localstorage
        window.localStorage.setItem(this.selectedAI.name, JSON.stringify(this.list));
        //重写发送消息
        this.question = lastMess.content;
        this.send();
      }

    },
    deleteAiOneMessageRecord() {
      this.list = this.list.filter(item => item !== this.rightMenu_mess_content_item3);
      //重写组装上下文
      this.aiChatMess = [];
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].role === "user") {
          this.aiChatMess.push({
            "role": "user",
            "content": this.list[i].content
          })
        } else {
          this.aiChatMess.push({
            "role": "assistant",
            "content": this.list[i].content
          })
        }
      }
      //重新保存聊天记录到localstorage
      window.localStorage.setItem(this.selectedAI.name, JSON.stringify(this.list));
    },
    getAiList() {
      aiApi.getAllAi().then((res) => {
        if (res.data.success) {
          this.aiList = res.data.data.aiList;
        }
      });
    },
    deleteSession() {
      const acceptId = this.rightMenu_mess_content_item2.acceptId;
      //提示
      this.$confirm('此操作将永久删除该会话及聊天记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sessionApi.deleteSession(acceptId).then((res) => {
          if (res.data.success) {
            this.$message.success('删除成功');
            this.getUserSessions();
          }
        });
      }).catch(() => {
        this.$message.info('已取消删除');
      });
    },
    hideSession() {
      sessionApi.hideSession(this.rightMenu_mess_content_item2.acceptId).then((res) => {
        if (res.data.success) {
          this.$message.success('隐藏成功');
          this.getUserSessions();
        }
      });
    },
    untopSession() {
      sessionApi.untopSession(this.rightMenu_mess_content_item2.acceptId).then((res) => {
        if (res.data.success) {
          this.$message.success('取消置顶成功');
          this.getUserSessions();
        }
      });
    },
    topSession() {
      sessionApi.topSession(this.rightMenu_mess_content_item2.acceptId).then((res) => {
        if (res.data.success) {
          this.$message.success('置顶成功');
          this.getUserSessions();
        }
      });
    },
    handleAcceptMessage(mess) {
      const message = JSON.parse(mess);
      this.userMessageRecordList.push(message);
      //定位到最新消息
      this.$nextTick(() => {
        this.setScreen()
      })
      if (message.contentType === 'image') {
        this.imgNowList.push(message.content)
      }
      //判断是否正在对话
      if (this.selectedItem) {
        //设为已读
        messageApi.readMessage(this.selectedItem.acceptId).then((res) => {
          if (res.data.success) {
            //清空未读消息
            this.selectedItem.unreadCount = 0;
            //如果是图片消息，不显示图片地址
            if (message.contentType === 'image') {
              this.selectedItem.lastMess = '[图片]';
            } else {
              this.selectedItem.lastMess = message.content;
            }
            store.state.user.hasUnreadMessage = 'false';
            window.localStorage.setItem('hasUnreadMessage', 'false');
          }
        });
      } else {
        //遍历会话列表，找到对应的会话
        for (let i = 0; i < this.userSessionList.length; i++) {
          if (this.userSessionList[i].acceptId === message.sendId) {
            if (message.contentType === 'image') {
              this.userSessionList[i].lastMess = '[图片]';
            } else {
              this.userSessionList[i].lastMess = message.content;
            }
            this.userSessionList[i].unreadCount++;
            break;
          }
        }
        store.state.user.hasUnreadMessage = 'true';
        window.localStorage.setItem('hasUnreadMessage', 'true');
      }
      this.$nextTick(() => {
        this.setScreen()
      })
    },
    //删除单条消息记录
    // deleteOneMessageRecord() {
    //   console.log(this.rightMenu_mess_content_item)
    //   let id = this.rightMenu_mess_content_item.id;
    //   messageApi.deleteSingleMessageRecord(id).then((res) => {
    //     if (res.data.success) {
    //       this.userMessageRecordList = this.userMessageRecordList.filter(item => item.id !== id);
    //       if (this.userMessageRecordList.length === 0) {
    //         //现在没有消息记录
    //         this.selectedItem.lastMess = '';
    //         sessionApi.updateLastMessage(this.selectedItem.acceptId, '').then((res) => {
    //           if (res.data.success) {
    //             // console.log("更新最后一条消息为空成功")
    //           }
    //         });
    //       } else {
    //         //现在还有消息记录，更新最后一条消息为最新的一条
    //         this.selectedItem.lastMess = this.userMessageRecordList[this.userMessageRecordList.length - 1].content;
    //         sessionApi.updateLastMessage(this.selectedItem.acceptId, this.selectedItem.lastMess).then((res) => {
    //           if (res.data.success) {
    //             // console.log("更新最后一条消息成功")
    //           }
    //         });
    //       }
    //     }
    //   });
    // },
    // 复制功能
    onCopy() {
      let msg = this.rightMenu_mess_content_item.content
      // 复制到粘贴板
      navigator.clipboard.writeText(msg)
          .then(() => {
            this.$message.success('复制成功')
          })
          .catch(err => {
            this.$message.error('复制失败：' + err)
          })
    },

    onCopy2() {
      let msg = this.rightMenu_mess_content_item3.content
      // 复制到粘贴板
      navigator.clipboard.writeText(msg)
          .then(() => {
            this.$message.success('复制成功')
          })
          .catch(err => {
            this.$message.error('复制失败：' + err)
          })
    },

    /**
     * 添加关闭右键菜单的监听器
     */
    addCloseRightMenu() {
      // 添加监听器，用于其他位置点击时，关闭打开的右键菜单,并清空获取的key
      document.addEventListener('click', () => {
        this.isShow_rightMenu_mess_content = false
        this.rightMenu_mess_content_item = ''
        this.isShow_rightMenu_mess_content2 = false
        this.rightMenu_mess_content_item2 = ''
        this.isShow_rightMenu_mess_content3 = false
        this.rightMenu_mess_content_item3 = ''
      })
      // 添加监听器，用于点击菜单内按钮时，关闭打开的右键菜单,并清空获取的key
      document.addEventListener('mousedown', (e) => {
        const {button} = e
        if (button === 2) {
          this.isShow_rightMenu_mess_content = false
          this.rightMenu_mess_content_item = ''
          this.isShow_rightMenu_mess_content2 = false
          this.rightMenu_mess_content_item2 = ''
          this.isShow_rightMenu_mess_content3 = false
          this.rightMenu_mess_content_item3 = ''
        }
      })
    },
    getSessionMessageRecord(item) {
      messageApi.getMessageRecord(item.acceptId).then(res => {
        this.userMessageRecordList = res.data.data.messageRecordVOS;
        for (let i = 0; i < this.userMessageRecordList.length; i++) {
          if (this.userMessageRecordList[i].contentType === 'image') {
            this.imgNowList.push(this.userMessageRecordList[i].content)
          }
        }
        //渲染后滚动到底部
        this.$nextTick(() => {
          this.setScreen()
        })
      })
    },
    // 右键菜单-聊天内容-事件
    show_rightMenu_mess_content(e, item) {
      this.isShow_rightMenu_mess_content = true
      // 定位
      this.rightMenu_mess_content_top = e.pageY
      this.rightMenu_mess_content_left = e.pageX
      // 获取当前选中内容
      this.rightMenu_mess_content_item = item
    },
    show_rightMenu_mess_content2(e, item) {
      this.isShow_rightMenu_mess_content2 = true
      // 定位
      this.rightMenu_mess_content_top2 = e.pageY
      this.rightMenu_mess_content_left2 = e.pageX
      // 获取当前选中内容
      this.rightMenu_mess_content_item2 = item
    },
    show_rightMenu_mess_content3(e, item) {
      this.isShow_rightMenu_mess_content3 = true
      // 定位
      this.rightMenu_mess_content_top3 = e.pageY
      this.rightMenu_mess_content_left3 = e.pageX
      // 获取当前选中内容
      this.rightMenu_mess_content_item3 = item
    },
    deleteMessageRecord() {
      this.$confirm('此操作将删除聊天记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'logout-message-box',
        type: 'warning'
      }).then(() => {
        messageApi.deleteMessageRecord(this.selectedItem.acceptId).then((res) => {
          if (res.data.success) {
            this.userMessageRecordList = [];
            //会话列表中的最后一条消息置空
            this.selectedItem.lastMess = '';
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    sendWsMessage() {
      //判断是否为空
      if (this.mess.trim() === '') {
        this.$message.warning('不能发送空文本');
        return;
      }
      const message = {
        sendId: this.userInfo.id,
        acceptId: this.selectedItem.acceptId,
        content: this.mess,
        contentType: 'message',
        sendNickname: this.userInfo.nickname,
        sendAvatar: this.userInfo.avatar,
        sendTime: new Date().getTime(),
      }
      let messageStr = JSON.stringify(message);
      sendMessage(messageStr);
      this.mess = '';
    },
    getUserSessions() {
      //获取用户会话列表
      sessionApi.getSessions().then((res) => {
        if (res.data.success) {
          this.userSessionList = res.data.data.sessionList;
          //获取路由中携带的id
          let id = this.$store.state.user.acceptId;
          if (id) {
            //找到对应的会话
            for (let i = 0; i < this.userSessionList.length; i++) {
              if (this.userSessionList[i].acceptId === id) {
                this.getChatObj(this.userSessionList[i])
                break;
              }
            }
          }
        }
      });
    },
    deleteAIRecord() {
      this.$confirm('此操作将删除聊天记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'logout-message-box',
        type: 'warning'
      }).then(() => {
        this.list = [];
        this.aiChatMess = [];
        //删除localstorage中的聊天记录
        window.localStorage.removeItem(this.selectedAI.name);
        this.$message({
          type: 'success',
          message: '删除成功!',
          duration: 1000
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
          duration: 1000
        });
      });
    },
    clean() {
      if (this.aiChatMess.length === 0) {
        this.$message.warning("没有上下文记录");
        return;
      }
      this.aiChatMess = [];
      this.$message.success("上下文已清空");
      this.list.push({
        content: "上下文已清空",
        role: "tip"
      });//显示用户聊天记录
      //定位页面位置
      this.setScreen();
      //保存到localstorage
      window.localStorage.setItem(this.selectedAI.name, JSON.stringify(this.list));
    },
    // // 聊天图片发送
    WssendImg() {
      this.sendImgLoad = true
      let formData = new FormData()
      formData.append('file', this.files)
      //将图片上传到oss
      ossApi.uploadFileToOss(formData).then((res) => {
        this.sendImgLoad = false
        if (res.data.code === 20000) {
          this.isShowDialog = false
          const message = {
            sendId: this.userInfo.id,
            acceptId: this.selectedItem.acceptId,
            content: res.data.data.url,
            contentType: 'image',
            sendNickname: this.userInfo.nickname,
            sendAvatar: this.userInfo.avatar,
            sendTime: new Date().getTime(),
          }
          let messageStr = JSON.stringify(message);
          sendMessage(messageStr);
          this.mess = '';
        } else {
          this.$message.error(res.data.message)
        }
      }).catch((err) => {
        this.sendImgLoad = false
        this.$message.error("图片上传失败")
      })
    },
    formatDate(date) {
      const createTime = new Date(date);
      const year = createTime.getFullYear();
      const month = ("0" + (createTime.getMonth() + 1)).slice(-2);
      const day = ("0" + createTime.getDate()).slice(-2);
      const hour = ("0" + createTime.getHours()).slice(-2);
      const minute = ("0" + createTime.getMinutes()).slice(-2);
      const second = ("0" + createTime.getSeconds()).slice(-2);
      const formattedDateTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      return formattedDateTime;
    },
    keyDown(e) {
      if (e.ctrlKey && e.keyCode === 13) { // 用户点击了ctrl+enter触发
        this.question += '\n'
      } else { // 用户点击了enter触发
        // this.Wssendmess('message', '')
        //发送消息
        this.send()
        e.preventDefault() // 阻止浏览器默认换行操作
        return false
      }
    },
    userkeyDown(e) {
      if (e.ctrlKey && e.keyCode === 13) { // 用户点击了ctrl+enter触发
        this.mess += '\n'
      } else { // 用户点击了enter触发
        // this.Wssendmess('message', '')
        //发送消息
        this.sendWsMessage()
        e.preventDefault() // 阻止浏览器默认换行操作
        return false
      }
    },
    // 表情包点击事件
    emojiDown(index) {
      this.question = this.question + this.emojiList[index]
    },
    userEmojiDown(index) {
      this.mess = this.mess + this.emojiList[index]
    },
    diaClose() {
      this.isShowDialog = false// 关闭窗口
    },
    showUpload() {
      let file = this.$refs.chartImageBig.files[0]
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return
      }

      // 格式无误后，预览文件处理
      this.imgSaveToUrl(file)
      this.isShowDialog = true
      // 复刻文件信息
      this.files = file
    },
    showUploadSmall() {
      let file = this.$refs.chartImageSmall.files[0]
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
        return
      }
      // 格式无误后，预览文件处理
      this.imgSaveToUrl(file)
      this.isShowDialog = true
      // 复刻文件信息
      this.files = file
    },
//     // 获取选中图片的预览路径，并赋值给本地img路径，在前端展示
    imgSaveToUrl(file) {
      // 获取上传图片的本地URL，用于上传前的本地预览，转换后的地址为 blob:http://xxx/7bf54338-74bb-47b9-9a7f-7a7093c716b5
      this.imageUrl = URL.createObjectURL(file)
    },
    handleResize() {
      if (window.innerWidth < 650) {
        // 窗口宽度小于650px的处理逻辑
        this.isBigView = false;
      } else {
        // 窗口宽度大于或等于650px的处理逻辑
        this.isBigView = true;
      }
    },
    getChatObj(item) {
      messageApi.readMessage(item.acceptId).then(res => {
        if (res.data.success) {
          item.unreadCount = 0;
        }
        //查一下现在还有没有未读消息
        messageApi.hasUnReadMessage().then(res => {
          if (res.data.success) {
            if (res.data.data.hasUnreadMessage) {
              window.localStorage.setItem('hasUnreadMessage', 'true');
              this.$store.state.user.hasUnreadMessage = 'true';
            } else {
              window.localStorage.setItem('hasUnreadMessage', 'false');
              this.$store.state.user.hasUnreadMessage = 'false';
            }
          }
        })
      })

      this.selectedItem = item;
      //查找该会话的聊天记录
      this.getSessionMessageRecord(item);
      this.isShowMess = false;
    },
    getAi(item) {
      this.loading = false;
      this.isShowMess = false;
      this.selectedItem = item;
      //根据item.id查找ai
      const ai = this.aiList.find(ai => ai.id === item.id);
      //从localstorage中获取聊天记录
      const localList = window.localStorage.getItem(ai.name);

      if (localList) {
        this.list = JSON.parse(localList);
      } else {
        this.list = [{
          content: ai.text,
          avatar: ai.avatar,
          sendTime: new Date().getTime(),
          nickname: ai.name,
          role: "assistant"
        }]
      }
      //重新组装上下文
      this.aiChatMess = [];
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].role === "user") {
          this.aiChatMess.push({
            "role": "user",
            "content": this.list[i].content
          })
        } else {
          this.aiChatMess.push({
            "role": "assistant",
            "content": this.list[i].content
          })
        }
      }
      //判断第一条上下文是否是assistant
      if (this.aiChatMess.length > 0 && this.aiChatMess[0].role === 'assistant') {
        //删除第一条上下文
        this.aiChatMess.shift();
      }
      this.selectedAI = ai;
      console.log(this.selectedAI)
    },
    handleClick(tab, event) {
      this.selectedItem = null;
    },
    hasCodeBlock(text) {
      const regex = /```[\s\S]*?```/g;
      return regex.test(text);
    },
    setScreen() {
      var message = document.getElementById('content_overflow')
      if (message) {
        message.scrollTop = message.scrollHeight;
      } else {
        message = document.getElementById('content_overflow2')
        if (message) {
          message.scrollTop = message.scrollHeight;
        } else {
          message = document.getElementById('content_overflow3')
          if (message) {
            message.scrollTop = message.scrollHeight;
          } else {
            message = document.getElementById('content_overflow4')
            if (message) {
              message.scrollTop = message.scrollHeight;
            }
          }
        }
      }
    },
    handleEnter(e) {
      if (e.key === "Enter" && !this.isMobile && !e.shiftKey) {
        this.send();
      }
    },
    highlightBlock(str, lang) {
      const codeIndex1 = parseInt(Date.now() + "") + Math.floor(Math.random() * 10000000);
      const codeIndex2 = parseInt(Date.now() + "") + Math.floor(Math.random() * 10000000);

      const clipboard = new ClipboardJS(`#copy-${codeIndex2}`);
      // 复制成功失败的提示
      clipboard.on("success", (e) => {
        this.$message.success("复制成功", e);
      });
      clipboard.on("error", (e) => {
        this.$message.error("复制失败", e);
      });

      return `<pre class="pre-code-box"><div class="pre-code-header" style="background-color: rgb(75, 85, 105);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: rgb(255,255,255);
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  line-height: 20px;
  padding: 5px 10px;
  user-select: none;"><span class="code-block-header__lang">${lang}</span><span id="copy-${codeIndex2}" class="code-block-header__copy" data-clipboard-action="copy" data-clipboard-target="#copy${codeIndex1}">复制代码</span></div><div class="pre-code" style="margin: 0;
  padding: 0;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
  position: relative;"><code id="copy${codeIndex1}" class="hljs code-block-body ${lang}">${str}</code></div></pre>`
    },
    getMdiText(value) {
      var _this = this
      const mdi = new MarkdownIt({
        linkify: true,
        highlight(code, language) {
          const validLang = !!(language && hljs.getLanguage(language))
          if (validLang) {
            const lang = language ?? ''
            return _this.highlightBlock(hljs.highlight(code, {language: lang, ignoreIllegals: true})
                .value, lang)
          }
          return _this.highlightBlock(hljs.highlightAuto(code).value, '')
        }
      })
      mdi.use(mdKatex, {blockClass: 'katexmath-block rounded-md p-[10px]', errorColor: ' #cc0000'})
      return mdi.render(value)
    },

    async send() {
      //判断上下文长度是否超过20
      if (this.aiChatMess.length > 20) {
        //提示用户清空上下文
        this.$message.warning("上下文长度超过20，请清空上下文后重试！");
        return;
      }
      //判断是否回复
      if (this.loading) return;
      this.loading = true;
      const message = this.question.trim();
      if (message == "") {
        this.$message.error("问题不能为空！");
        this.loading = false;
        return;
      }
      this.list.push({
        content: message,
        avatar: this.userInfo.avatar,
        sendTime: new Date().getTime(),
        nickname: this.userInfo.nickname,
        role: "user"
      });//显示用户聊天记录

      //定位页面位置
      this.setScreen();
      this.question = "";
      this.list.push({
        content: "",
        avatar: this.selectedAI.avatar,
        sendTime: new Date().getTime(),
        nickname: this.selectedAI.name,
        role: "assistant"
      });//显示机器人聊天记录

      this.aiChatMess.push({
        "role": "user",
        "content": message
      })

      //判断是否是文心一言
      if (this.selectedAI.name === '文心一言') {
        //文心一言的上下文格式，数组数量必须是奇数，且奇数role必须是assistant，偶数role必须是user
        if (this.aiChatMess.length % 2 === 0) {
          this.$message.warning("文心一言的上下文格式错误！请删除聊天记录后重试！");
          this.loading = false;
          return;
        }
      }


      let model = this.selectedAI.model;
      let apiUrl = this.selectedAI.apiUrl;
      let token = this.selectedAI.token;
      if (process.env.GPT_MODEL) {
        model = process.env.GPT_MODEL;
      }
      const toAiObj = {
        "model": model,
        "messageDTOList": this.aiChatMess,
        "apiUrl": apiUrl,
        "token" : token
      }

      //流式请求
      const res = await fetch(this.selectedAI.url, {
        method: 'POST',
        dataType: "text/event-stream",
        headers: {
          'Content-Type': 'application/json',
          'token': cookie.get('qa_token')
        },
        body: JSON.stringify(toAiObj)
      })
      const reader = res.body.getReader()
      let decoder = new TextDecoder();
      let flag = true;
      while (flag) {
        const {done, value} = await reader.read();
        if (done) {
          flag = false;
          break;
        }
        this.list[this.list.length - 1].content += decoder.decode(value);
        if (this.list[this.list.length - 1].content) {
          this.loading = false;
          this.setScreen();
        }
      }

      //对代码块进行处理
      // if (this.hasCodeBlock(this.list[this.list.length - 1].content)) {
      //   this.list[this.list.length - 1].content = this.getMdiText(this.list[this.list.length - 1].content);
      // }
      // //如果文本里面有图片，将图片的大小设置为50%
      // this.list[this.list.length - 1].content = this.list[this.list.length - 1].content.replace(/<img/g, '<img style="width:50%"');
      this.aiChatMess.push({
        "role": "assistant",
        "content": this.list[this.list.length - 1].content
      })


      //定位页面位置
      this.setScreen();
      //保存聊天记录到localstorage
      window.localStorage.setItem(this.selectedAI.name, JSON.stringify(this.list));
    },
  }
}
</script>
<style lang="scss">
@media screen and (max-width: 600px) {
  .logout-message-box {
    width: 300px !important;
  }
}

.viewer p {
  line-height: inherit;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
</style>
<style scoped>

/*右键菜单css样式*/
.rightMenu_mess_content {
  position: fixed;
}

.rightMenu_mess_content_card /deep/ .el-card__body {
  padding: 0;
}

.rightMenu_mess_content_card /deep/ .el-button {
  border: 0;
  padding: 12px 30px;
}

.rightMenu_mess_content_card_item {
  border-bottom: solid 1px #eeeded;
}


.dialogUpload /deep/ .el-dialog {
  width: 400px;
  text-align: center;
}

.dialogUpload /deep/ .el-dialog__body {
  padding: 10px;
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

/* 如果你的 el-input type 设置成textarea ，就要用这个了 */
.inputDeep >>> .el-textarea__inner {
  border: 0;
  resize: none; /* 这个是去掉 textarea 下面拉伸的那个标志，如下图 */
}

.darkInput >>> .el-textarea__inner {
  background-color: rgb(17, 17, 17);
}

.dlog_footer {
  width: 100%;
}

.footer_content {
  margin: 10px;
}

.footer_content_menu {
  display: flex;
  height: 30px;
  width: 100%;
}

.footer_content_menu_face {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 20px;
}

.footer_content_menu_face:hover .icon-face {
  color: #01a3fc;
}

.face_list {
  width: 300px;
  height: 200px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.face-item {
  width: 30px;
  height: 30px;
  border: solid 1px #e0e0e0;
  margin: 0 -1px -1px 0; /*解决相邻border看起来很粗问题*/
  text-align: center;
  font-size: 20px;
}

.face-item:hover {
  background: #eeeeee;
}

.footer_content_menu_img {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 20px;
  margin-left: 5px;
}

.footer_content_menu_img:hover .icon-img {
  color: #01a3fc;
}

.content_me_bgd {
  border-radius: 6px;
  position: relative;
  display: inline-block;
  padding: 0px 6px;
  width: auto;
  /*height: auto;*/
  line-height: 30px;
  background: rgb(210,249,209);
  z-index: 0;
  text-align: left;
}

.content_me_bgd::after {
  border-style: solid;
  border-width: 0 0 11px 11px;
  border-color: transparent transparent transparent rgb(210,249,209);
  content: "";
  position: absolute;
  top: 10px;
  right: -8px;
  margin-top: -10px;
  display: block;
  width: 0px;
  height: 0px;
  z-index: -1;
}

.mess_me {
  text-align: right;
  margin-right: 10px;
}

.content_me {
  width: 70%;
  display: flex;
  justify-content: flex-end;
  float: right;
  margin: 11px 18px;
}

.dlog_content {
  width: 100%;
  height: 600px;
  border-bottom: 1px solid #cecdcd;
  overflow-y: auto;
  overflow-x: hidden;
}

.content_other {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  margin: 11px 18px;
}

/*上传图片预览的dialog*/
.img_left {
  text-align: left;
}

.images {
  height: 150px;
  fit: contain;
}

.mess_other {
  text-align: left;
  margin-left: 10px;
}

/*其他用户的气泡*/
.content_other_bgd {
  border-radius: 6px;
  position: relative;
  display: inline-block;
  padding: 0px 6px;
  width: auto;
  height: auto;
  line-height: 34px;
  background: #f2f6fc;
  z-index: 0;
}

/*气泡前的小三角指向*/
.content_other_bgd::before {
  border-style: solid;
  border-width: 0 11px 11px 0;
  border-color: transparent #f2f6fc transparent transparent;
  content: "";
  position: absolute;
  top: 10px;
  left: -8px;
  margin-top: -9px;
  display: block;
  width: 0px;
  height: 0px;
  z-index: 0;
}

.dlog_header {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #cecdcd;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.mess-container {
  height: 100vh;
  display: flex;
}

.darkMess {
  background-color: rgb(17, 17, 17);
}

.darkSearch /deep/ .el-input__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}


.darkTab >>> .el-tabs__header .el-tabs__item {
  color: #ffffff;
}

.darkName {
  color: #ffffff;
}

.mess {
  width: 500px;
  min-width: 250px;
  height: 100%;
  border-right: rgb(230, 230, 230) 1px solid;
}

.selected {
  background-color: rgb(209, 222, 240) !important;
}

.selected .darkName {
  color: black;
}


img {
  width: 500px;
}

.ai_list_item, .chat_list_item {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: rgb(235, 238, 245) solid 1px;
  margin-bottom: 5px;
}

.ai_list_item:hover, .chat_list_item:hover {
  background-color: rgb(209, 222, 240);
}

.isTopListItem {
  background-color: rgb(252, 232, 232);
}

.pre-code-box {
  border-radius: 4px;
  border: 1px solid #eaecef;
  background-color: rgb(210,249,209);
  margin: 0;
  padding: 0;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
  position: relative;
}

.pre-code-header {
  background-color: rgb(210,249,209);
  border-bottom: 1px solid #eaecef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #6a737d;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  line-height: 20px;
  padding: 5px 10px;
  user-select: none;
}

.code-block-header__lang {
  color: #6a737d;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  line-height: 20px;
}

.code-block-header__copy {
  color: #6a737d;
  cursor: pointer;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  line-height: 20px;
}

.pre-code {
  margin: 0;
  padding: 0;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
  position: relative;
}

.code-block-body {
  margin: 0;
  padding: 16px;
  background-color: rgb(210,249,209);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  color: #24292e;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 14px;
  line-height: 1.5;
  overflow: auto;
  word-break: normal;
  word-wrap: normal;
}

.code-block-body .hljs-comment,
.code-block-body .hljs-quote {
  color: #6a737d;
}

.page {
  /** 100%-300px */
  width: 100%;
  position: relative;
  height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
}

.small-view-page {
  width: 100%;
  position: relative;
  height: 100vh;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
}

.blank-page {
  width: 100%;
  height: 100vh;
  background: #fbfcfc;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.darkBlankPage {
  background-color: rgb(17, 17, 17);
  color: white;
}


.btn-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 32px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
}

.btn-send:hover {
  cursor: pointer;
  opacity: 0.85;
}


/*#myList {*/
/*  width: 90%;*/
/*  margin: 0 auto;*/
/*  overflow-x: hidden;*/
/*  overflow-y: auto;*/
/*}*/


::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.listImg {
  margin-top: 5px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.listText {
  margin-left: 20px;
  padding-top: 10px;
  width: 100%;
  white-space: pre-wrap;
}

.darkText {
  color: #ffffff;
}


.inputbox {
  position: sticky;
  bottom: 30px;
  /*left: 400px;*/
  /*right: 0;*/
  /*margin: auto;*/
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  top: calc(100vh - 100px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding-right: 12px;*/
  background: #fff;
  border-radius: 8px;
}

.darkInput /deep/ .el-input__inner {
  background-color: black;
  border: rgb(62, 62, 62) solid 1px !important;
  color: white;
}

.inputbox button {
  margin-left: 15px;
  width: 56px;
  height: 82%;
  border-radius: 6px;
  border: 0;
  background: silver;
  color: #333;
  font-size: 14px;
  outline: none;
}

.inputbox button:hover {
  cursor: pointer;
  opacity: 0.8;
}

.addin {
  margin: 10px 20px;
  width: 30px;
  height: 30px;
}

.steppingstone {
  width: 100%;
  height: 160px;
}

.begintitle {
  width: 100%;
  /* padding: 50px 50px 30px 50px; */
}

.begintitle h1 {
  padding: 50px;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}


.witem p {
  margin: auto;
  padding: 10px;
  margin-top: 15px;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
}

.witem h3 {
  padding: 15px;
  font-size: 20px;
  color: #606266;
  text-align: center;
}

textarea {
  border: none;
  resize: none;
  cursor: pointer;
  outline: none;
  overflow-y: hidden;
}

@media screen and (max-width: 650px) {
  .page {
    display: none;
  }


  .blank-page {
    display: none;
  }

  .mess {
    width: 100%;
  }

  .small-view-page {
    display: block;
  }

  .listImg {
    width: 30px;
    height: 30px;
  }

  .addin {
    width: 20px;
    height: 20px;
  }

  .listText {
    font-size: 12px;
    width: 75%;
  }

  .dlog_content {
    width: 100%;
    height: 400px;
    border-bottom: 1px solid #cecdcd;
    overflow-y: auto;
    overflow-x: hidden;
  }

  /*其他用户的气泡*/
  .content_other_bgd {
    border-radius: 6px;
    position: relative;
    display: inline-block;
    padding: 0px 6px;
    width: auto;
    height: auto;
    max-width: 250px;
    line-height: 34px;
    background: #f2f6fc;
    z-index: 0;
  }

  .content_me_bgd {
    border-radius: 6px;
    position: relative;
    display: inline-block;
    padding: 0px 6px;
    width: auto;
    /*height: auto;*/
    max-width: 250px;
    line-height: 30px;
    background: rgb(210,249,209);
    z-index: 0;
    text-align: left;
  }
}


</style>
